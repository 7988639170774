<template>
  <div class="row no-gutters flex-md-10-auto" data-cy="project-view-page">
    <div class="col-md-4 col-lg-5 col-xl-4 order-md-1">
      <div class="content">
        <div class="row">
          <div class="col-md-8">
            <h2 class="h2 font-w400 mb-3">{{ project.name }}</h2>
          </div>
          <div data-cy="project-edit" class="col-md-4 text-right">
            <router-link
              v-if="$permissions.write('asset', asset)"
              class="btn btn-primary"
              :to="{ name: 'asset-projects-edit', params: { projectId: project._id } }"
            >
              <i class="fa fa-pencil mr-1"></i> Edit
            </router-link>
          </div>
        </div>
        <h2 class="h4 font-w400 mb-3">Completion</h2>
        <div class="progress push">
          <div class="progress-bar" :style="{ width: `${progress}%` }">
            <span class="font-size-sm font-w600">{{ progress }}%</span>
          </div>
        </div>
        <p class="text-muted pt-3 my-3">
          <span class="font-w600 font-size-h5">Summary</span><br />
          <span class="project-description">{{ project.description }}</span>
        </p>
        <p class="text-muted border-top pt-3 my-3">
          <span class="font-w600 font-size-h5">Status</span><br />
          <span class="text-capitalize">{{ project.status }}</span>
        </p>
        <p class="text-muted border-top pt-3 my-3">
          <span class="font-w600 font-size-h5">Start</span><br />
          {{ project.startDate | date('Do MMM YYYY') }}
        </p>
        <p class="text-muted border-top pt-3 my-3">
          <span class="font-w600 font-size-h5">End</span><br />
          {{ project.endDate | date('Do MMM YYYY') }}
        </p>

        <div class="text-muted border-top pt-3 my-3">
          <div class="font-w600 font-size-h5 mb-2">Accounts</div>
          <div v-for="(item, index) in accountsAssigned" :key="index" class="MultiSelect-item mb-1 p-2 font-w600">
            {{ item.name }}
          </div>
        </div>
        <div class="text-muted border-top pt-3 my-3">
          <div class="font-w600 font-size-h5 mb-2">Document Attachments</div>
          <div v-for="(item, index) in projectDocuments" :key="index" class="MultiSelect-item mb-1 p-2 font-w600">
            <a href="#" @click.prevent="onClickDownload(item)"
              ><FileIcon class="fa-fw mr-1" :extension="item.extension || item.type" /> {{ item.name }}.{{ item.extension }}</a
            >
          </div>
          <div v-for="(item, index) in project.documents" :key="index" class="MultiSelect-item mb-1 p-2 font-w600">
            <a href="#" @click.prevent="onClickDownload(item, true)"
              ><FileIcon class="fa-fw mr-1" :extension="item.extension || item.type" /> {{ item.name }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8 col-lg-7 col-xl-8 order-md-0 bg-body-dark">
      <div class="content content-full">
        <h3>Tasks</h3>
        <form v-if="$permissions.write('asset', asset)" @submit.prevent="onSubmitTask">
          <div class="d-flex align-items-center">
            <FormItem
              id="task-input"
              v-model="taskInput"
              type="text"
              name="task-input"
              placeholder="Add a task and press enter.."
              :disabled="loadingAction.createProjectTask"
            />
            <span v-if="loadingAction.createProjectTask" class="ml-2"><i class="fa fa-spinner fa-spin fa-fw"></i></span>
          </div>
        </form>

        <h2 class="content-heading pb-0 mb-3 border-0">
          Pending <span class="js-task-badge-completed badge badge-pill badge-light animated fadeIn">{{ pendingTasks.length }}</span>
        </h2>
        <div
          v-for="task in pendingTasks"
          :key="task._id"
          class="block block-rounded mb-2 py-2 px-3 d-flex justify-content-between align-items-center"
        >
          <div class="custom-control custom-checkbox custom-checkbox-rounded-circle custom-control-primary custom-control-lg">
            <input
              v-if="$permissions.write('asset', asset)"
              :id="task._id"
              type="checkbox"
              class="custom-control-input"
              :name="task._id"
              @input.prevent="onCheckTask(task, 'completed')"
            />
            <label class="custom-control-label" :for="task._id"></label>

            <strong>{{ task.name }}</strong>
          </div>
          <div>
            <button
              v-if="$permissions.write('asset', asset)"
              type="button"
              class="btn btn-sm btn-link text-danger"
              @click.prevent="onDeleteTask(task._id)"
            >
              <i class="fa fa-times fa-fw"></i>
            </button>
          </div>
        </div>

        <h2 class="content-heading pb-0 mb-3 border-0">
          Completed <span class="js-task-badge-completed badge badge-pill badge-light animated fadeIn">{{ completedTasks.length }}</span>
        </h2>
        <div
          v-for="task in completedTasks"
          :key="task._id"
          class="block block-rounded mb-2 py-2 px-3 d-flex justify-content-between align-items-center"
        >
          <div class="custom-control custom-checkbox custom-checkbox-rounded-circle custom-control-primary custom-control-lg">
            <input
              :id="task._id"
              type="checkbox"
              class="custom-control-input"
              :name="task._id"
              checked=""
              @input.prevent="onCheckTask(task, 'pending')"
            />
            <label class="custom-control-label" :for="task._id"></label>

            <strong
              ><del>{{ task.name }}</del></strong
            >
          </div>
          <div>
            <button
              v-if="$permissions.write('asset', asset)"
              type="button"
              class="btn btn-sm btn-link text-danger"
              :disabled="loadingAction.deleteProjectTask === task._id"
              @click.prevent="onDeleteTask(task._id)"
            >
              <i v-if="loadingAction.deleteProjectTask !== task._id" class="fa fa-times fa-fw"></i>
              <i v-else class="fa fa-spinner fa-spin fa-fw"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="content content-full">
        <h3>Analysis</h3>
        <div v-if="!selectedAccount && !loading" class="alert alert-primary">
          Link this project to an account to see energy data before and after the project end date.
        </div>
        <div v-else-if="selectedAccount && !loading">
          <p>See the energy usage before/after this project ended.</p>
          <div v-if="project.status === 'completed' && completedProjectCheck && project.accountIds.length !== 0">
            <FormGroup
              id="accounts"
              v-model="selectedAccount"
              label="Select Account to Analyse"
              placeholder="Add an account..."
              type="select"
              :options="accountsAssigned.map(a => ({ label: a.name, value: a.id }))"
              @input="handleSelect"
            />
            <div v-if="!loadingAction.getAssetAnalytics.accountEnergy">
              <strong>
                <div class="mb-3">Consumption 12 months prior to completion:</div>
              </strong>
              <p class="ml-5">{{ handleTotalConsumptionBefore }} kWh</p>
              <strong>
                <div class="mb-3">Consumption 12 months after completion:</div>
              </strong>
              <p class="ml-5">{{ handleTotalConsumptionAfter }} kWh</p>
            </div>
            <div v-else class="d-flex justify-content-center">
              <div class="text-center">
                <div class="spinner-border spinner-lg text-secondary mb-4" role="status">
                  <span class="sr-only">Loading data...</span>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="project.accountIds.length !== 0" data-cy="data-error" class="alert alert-warning">
            Graphs and data will show once this project has completed.
          </div>
          <div v-else data-cy="account-error" class="alert alert-warning">No account assigned to project.</div>
        </div>
        <div v-else class="d-flex justify-content-center">
          <div class="text-center">
            <div class="spinner-border spinner-lg text-secondary mb-4" role="status">
              <span class="sr-only">Loading data...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import FileIcon from '@/components/FileIcon';
import FormItem from '@/components/FormItem';
import FormGroup from '@/components/FormGroup';

export default {
  name: 'AssetProjectView',
  components: {
    FileIcon,
    FormItem,
    FormGroup
  },
  data() {
    return {
      taskInput: '',
      accountsAssigned: [],
      selectedAccount: '',
      chartKey: 0,
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      projects: 'asset/projects',
      accounts: 'asset/accounts',
      loadingAction: 'asset/loadingAction',
      errorAction: 'asset/errorAction'
    }),
    completedProjectCheck() {
      return moment().isAfter(this.project.endDate);
    },
    pendingTasks() {
      return (this.project.tasks || []).filter(t => t.status === 'pending');
    },
    activeTasks() {
      return (this.project.tasks || []).filter(t => t.status === 'active');
    },
    completedTasks() {
      return (this.project.tasks || []).filter(t => t.status === 'completed');
    },
    progress() {
      if (this.project.tasks.length === 0) {
        return 0;
      }

      return (((this.project.tasks || []).filter(t => t.status === 'completed').length / (this.project.tasks || []).length) * 100).toFixed(0);
    },
    project() {
      return this.projects.find(project => project._id === this.$route.params.projectId) || { tasks: [] };
    },
    projectDocuments() {
      const projectDocuments = this.asset.documents.filter(d => (this.project.documentIds || []).includes(d._id));

      const sortedDocuments = projectDocuments.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      return sortedDocuments;
    }
  },
  async created() {
    if (!this.projects) {
      await this.listProjects({ params: { assetId: this.$route.params.id } });
    }
  },
  async mounted() {
    const accounts = await this.getAccounts({ id: this.$route.params.id });

    if (this.project.accountIds?.length > 0) {
      this.selectedAccount = this.project.accountIds[0];

      const projectAccounts = this.project.accountIds.map(a => {
        return accounts.find(account => account._id === a);
      });

      this.accountsAssigned = projectAccounts
        .filter(a => a)
        .map(a => {
          return { name: a.name, id: a._id };
        });
    }

    this.loading = false;
  },
  methods: {
    ...mapActions({
      listProjects: 'asset/listProjects',
      createProjectTask: 'asset/createProjectTask',
      updateProjectTask: 'asset/updateProjectTask',
      deleteProjectTask: 'asset/deleteProjectTask',
      updateProject: 'asset/updateProject',
      getAccounts: 'asset/accounts',
      downloadProjectAttachment: 'asset/downloadProjectAttachment',
      downloadDocument: 'asset/downloadDocument'
    }),
    async onSubmitTask() {
      try {
        const createProjectTask = await this.createProjectTask({
          id: this.$route.params.projectId,
          data: {
            name: this.taskInput,
            status: 'pending'
          }
        });

        if (createProjectTask._id) {
          this.taskInput = '';
          this.$toasted.success('Task added successfully.');

          if (moment().isAfter(this.project.startDate)) {
            this.updateProject({ id: this.$route.params.projectId, data: { status: 'active' } });
          }
        }
      } catch (e) {
        this.$toasted.error('Could not add task.');
      }
    },
    async onClickDownload(doc, isLegacy = false) {
      if (!doc.s3Key) return false;

      try {
        let downloadUrl;

        if (isLegacy) {
          downloadUrl = await this.downloadProjectAttachment({
            id: this.project._id,
            documentId: doc._id,
            name: doc.name
          });
        } else {
          downloadUrl = await this.downloadDocument({
            id: this.asset._id,
            documentId: doc._id,
            name: doc.name
          });
        }
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = downloadUrl;
        link.download = doc.name;
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        this.$toasted.error(e.errorMsg || 'Could not download file', { position: 'bottom-center', duration: 3000 });
      }
    },
    async onDeleteTask(taskId) {
      try {
        const deleteProjectTask = await this.deleteProjectTask({
          id: this.$route.params.projectId,
          taskId
        });

        if (deleteProjectTask._id) {
          this.$toasted.success('Task deleted successfully.');
        }
      } catch (e) {
        this.$toasted.error('Could not delete task.');
      }
    },
    async onCheckTask(task, status) {
      try {
        const updateProjectTask = await this.updateProjectTask({
          id: this.$route.params.projectId,
          data: { name: task.name, status },
          taskId: task._id
        });

        if (updateProjectTask._id) {
          this.$toasted.success('Task updated successfully.');

          if (this.project.tasks.every(t => t.status === 'completed')) {
            this.updateProject({ id: this.$route.params.projectId, data: { status: 'completed' } });
          } else {
            this.updateProject({ id: this.$route.params.projectId, data: { status: 'active' } });
          }
        }
      } catch (e) {
        this.$toasted.error('Could not update task.');
      }
    },
    async handleSelect(value) {
      this.selectedAccount = value;
      this.chartKey += 1;
    }
  }
};
</script>
<style lang="scss">
.project-description {
  white-space: break-spaces;
}
</style>
